.post__image{
    max-width: 500px;
    margin: auto;
    text-align: center;
}

.post__image img{
    border: 2px solid;
    border-radius: 10px;
    background: #1E2313;
    width: 350px;
    height: 300px;
}

.custom__textarea{
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid #86AC1A;
    background-color: transparent;
    border-radius: 10px;
    resize: vertical;
    color: #fff;
}