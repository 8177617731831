.first__content {
    text-align: center;
}

.first__content h2 {
    color: #fff;

}


.frens h1 {
    color: #fff;
    font-size: 36px;
    font-weight: 400;
    line-height: 39px;
    letter-spacing: -0.16500000655651093px;
    text-align: center;

}



input {
    display: block;
    border: 2px solid #86AC1A;
    background: transparent;
    color: #fff;
    width: 100%;
    padding: 15px;
    border-radius: 10px;

}


input:active {
    border: 2px solid #86AC1A;
}

.form__layout {
    max-width: 500px;
    margin: auto;
}

.login_details{
    margin: auto;
    text-align: center;
}

.login_details .icon{
    width: 85px;
}

.login_details p{
    text-align: center;
    margin-top: 25px;
}


table {
    /* border-collapse: collapse; */
    background-color: transparent;
    margin: auto;
}
th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    color: white;
}
th {
    background-color: transparent;
}