:root {
  --primary: #86AC1A;
  --secondary: #0e0e0e;
}

p {
  font-size: 16px;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #86AC1A;
}
a:hover{
  color: inherit;
}

input:focus{
  border: 1px solid red;
}

.navbar ul li a:hover {
  color: #fff;
  border-bottom: 2px solid #86AC1A;
}

a:active {
  color: inherit;
}

.navbar ul li {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #86AC1A;

}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

.nav-brand img {
  width: 70px;
}

.harmburger {
  display: none;
  padding: 5px;
}

.bar {
  display: block;
  width: 20px;
  height: 3px;
  margin: 2px auto;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  border-radius: 5px;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.nav-item {
  margin-left: 2rem;
  font-family: "Roboto";
}





@media only screen and (max-width: 768px) {
  .navContainer {
    margin: auto;
  }

  .nav-brand img {
    width: 50px;
  }

  .nav-menu {
    z-index: 100000;
    position: fixed;
    left: -100%;
    scroll-behavior: inherit;
    top: 4.5rem;
    flex-direction: column;
    background-color: #0e0e0e;
    color: #fff;
    width: 100%;
    border-radius: 10px;
    text-align: start;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 1rem -1rem;
    width: 100%;
  }

  .nav-item a {
    font-size: 16px;
  }

  .harmburger {
    display: block;
    cursor: pointer;
  }

  .harmburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .harmburger.active .bar:nth-child(1) {
    transform: translateY(5px) rotate(45deg);
  }

  .harmburger.active .bar:nth-child(3) {
    transform: translateY(-5px) rotate(-45deg);
  }

  .nav_blank__btn{
    text-align: center;
    border-radius: 8px;
    background: #1E2313;
    padding: 10px 15px;
    font-weight: 600;
    cursor: pointer;
    font-size: 10px;
  }
  .nav__btn{
    margin-left: 150px;
  }

}

.grid-container {
  display: grid;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  text-align: center;
  width: 100%;
}

.filled__btn {
  width: 176px;
  text-align: center;
  padding: 15px;
  border-radius: 8px;
  color: #0e0e0e;
  font-weight: 600;
  background: linear-gradient(103.64deg, #B7E33C 3.19%, #86AC1A 76.57%);
  margin-right: 10px;
  cursor: pointer;
}

.blank__btn {
  text-align: center;
  width: 176px;
  border-radius: 8px;
  background: #1E2313;
  padding: 15px;
  font-weight: 600;
  margin-right: 10px;
  cursor: pointer;
}

button {
  border: 0;
  padding: 10px;
  border-radius: 4px;
  color: var(--secondary);
  background: var(--primary);
  cursor: pointer;
}

.nav_blank__btn{
  text-align: center;
  border-radius: 8px;
  background: #1E2313;
  padding: 10px 15px;
  font-weight: 600;
  cursor: pointer;
}