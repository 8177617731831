.first__content h3, p {
    text-align: start;
}

.first__content p{
    color: #fff;
}

input{
    display: block;
    border: 2px solid #86AC1A;
    background: transparent;
    color: #fff;
    width: 100%;
    height: 50px;
    padding: 15px;
    border-radius: 10px;
}

button {
    width: 50%;
    height: 45px;
    padding: 15px;
    border-radius: 10px;
}


input:active{
    border: 2px solid #86AC1A;
}

.form__layout{
    max-width: 500px;
    margin: auto;
}

.table__width{
    margin: auto;
    max-width: 800px;
}

table{
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 5px;
}

th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    color: white;
}
th {
    background-color: transparent;
}