:root {
    --primary: #86AC1A;
    --secondary: #0e0e0e;
}

.landing__page h1 {
    font-size: 64px;
    font-weight: 600;
    line-height: 75px;
    letter-spacing: -0.16500000655651093px;
    text-align: center;

}

.landing__page p {
    font-family: Open Sans;
    font-size: 22px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;

}

.socials {
    margin: auto;
    text-align: center;
    justify-content: center;
}

.web__info {
    margin-left: 200px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;

}

.web__info .content {
    padding: 10px;
    border-radius: 12px;
    gap: 10px;
    background: #171A0E;
    text-align: center;
}

.web__info .content h3 {
    color: #fff;
}

i, svg{
    width: 16px;
    margin-right: 3px;
    margin-top: -3px;
}

.how__work {
    margin: auto;
    text-align: center;
    background-color: #000;
    padding: 15px;
    border: 1px solid #000;
    border-radius: 10px;
    width: 256px;

}


@media only screen and (max-width: 768px) {

    .frens h1 {
        color: #fff;
        font-size: 26px;
        font-weight: 400;
        line-height: 39px;
        letter-spacing: -0.16500000655651093px;
        text-align: center;
    
    }
    


    .landing__page h1 {
        font-size: 34px;
        font-weight: 600;
        line-height: 45px;
        letter-spacing: -0.16500000655651093px;
        text-align: center;

    }

    .landing__page p {
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;

    }

    .web__info {
        margin: auto;
    }
}

.ads_page{
    max-width: 500px;
    margin: auto;
    text-align: center;
    justify-content: center;
}